import { mapApiDataToHomepage } from '@/lib/cms-mappers';
import { blogPostsLocales } from '@/config/locales';

import type { FHomepageTemplateProps } from '@/components/templates/FHomepageTemplate.vue';

/**
 * Returns homepage data fetched from CMS (Strapi)
 * Note: for now, based on guides data
 */
export async function useCmsHomepage(): UseCmsResponse<FHomepageTemplateProps> {
  const { t } = useI18n();

  const [
    {
      data: homepageData,
      error: homepageError,
      pending: homepagePending,
      refresh: homepageRefresh,
    },
    {
      data: blogPostsData,
      error: blogPostsError,
      pending: blogPostsPending,
      refresh: blogPostsRefresh,
    },
  ] = await Promise.all([
    useStrapi('homepage', {
      queryParams: {
        populate: ['featured_use_cases', 'featured_use_cases.bike_image'],
      },
    }),
    useStrapi('homepage', {
      localesSubset: blogPostsLocales,
      queryParams: {
        populate: ['explore_section.blog_posts.main_image'],
      },
    }),
  ]);

  const data = computed(() => ({
    ...homepageData.value?.data,
    attributes: {
      ...homepageData.value?.data.attributes,
      ...blogPostsData.value?.data.attributes,
    },
  }));

  const mappedData = computed(() => mapApiDataToHomepage(data.value));
  const i18nMappedData = computed<FHomepageTemplateProps>(() => ({
    blogPostCards: mappedData.value.blogPostCards.map(card => ({
      ...card,
      linkText: t('resource_guides__card__button_text'),
    })),
    lightOnCards: mappedData.value.lightOnCards,
  }));

  return {
    data: i18nMappedData,
    error: computed(() => homepageError.value || blogPostsError.value),
    pending: computed(() => homepagePending.value || blogPostsPending.value),
    refresh: () => {
      homepageRefresh();
      blogPostsRefresh();
    },
  };
}
