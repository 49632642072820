<template lang="pug">
.FHeatMap(
  ref="heatMapRef"
  :class="classes"
)
  .FHeatMap__text
    slot
  .FHeatMap__wrapper
    FBackgroundImage(
      src="/images/home/map.png"
      placeholder="/images/home/map.placeholder.png"
      size="cover"
      blend-mode="screen"
    )
    .FvgImage__pathWrapper
      FSvgImage(
        :component="mapPathSvg"
        height="100%"
        width="100%"
        :stroke-color="pathColor"
        fill-color="none"
      )
</template>

<style lang="stylus">
.FHeatMap
  display flex
  flex-direction column
  background var(--color--secondary)
  overflow hidden
  color var(--color--neutral--light-5)
  min-height 100vh

.FHeatMap__text
  max-width rem(864)
  padding-top rem(200)
  margin 0 auto
  text-align center
  z-index 2

  h2
    use-font('heading-3')

  p
    use-font('body-2')
    margin-top rem(16)

  +media-down('sm')
    padding rem(110) rem(24) 0
    text-align left

.FHeatMap__wrapper
  display flex
  position relative
  align-items center
  justify-content center
  width 100vw
  height calc((100vw / 1.95)) // To maintain image ratio
  margin-top rem(-220)

  +media-down('sm')
    margin-top 0

  &::before,
  &::after
    content ''
    height 33%
    width 100%
    position absolute
    z-index 1

  &::before
    top 0
    background linear-gradient(to top, transparent, var(--color--secondary))

  &::after
    bottom 0
    background linear-gradient(to bottom, transparent, var(--color--secondary))

.FvgImage__pathWrapper
  position absolute
  width 100%
  height 100%
  padding-top 10%

  svg path
    stroke-dasharray 1600 1600
    stroke-dashoffset 1600
    animation map-paths 2s ease-in-out forwards
    animation-play-state paused

.FHeatMap--animate
  .FvgImage__pathWrapper
    svg
      path
        animation-play-state running

@keyframes map-paths
  0%
    stroke-dashoffset 1600

  100%
    stroke-dashoffset 0
</style>

<script setup lang="ts">
import mapPathSvg from '@/assets/images/home/map_path.svg?component';

export interface FHeatMapProps {
  /**
   * Color of the path
   */
  pathColor?: string;
}

const heatMapRef = ref(null);
const heatMapIsVisible = ref(false);

const emit = defineEmits<{
  (name: 'visibility-change', newValue: boolean): void;
}>();

withDefaults(defineProps<FHeatMapProps>(), {
  pathColor: 'primary',
});

useIntersectionObserver(
  heatMapRef,
  ([{ isIntersecting }]) => {
    heatMapIsVisible.value = isIntersecting;
    emit('visibility-change', heatMapIsVisible.value);
  },
  { threshold: 0.5 }
);

const classes = computed(() => ({
  'FHeatMap--animate': heatMapIsVisible.value,
}));
</script>
