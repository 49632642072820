<template lang="pug">
header.FHero(
  :class="classes"
  :style="style"
)
  .FHero__image(:style="imageStyle")
  .FHero__content
    h6.FHero__tag(v-if="tag") {{ tag }}
    h1.FHero__title(v-html="title")
    // @slot The default slot for additional content
    slot
</template>

<style lang="stylus">
.FHero
  position relative
  min-height 100vh
  background-color var(--FHero--backgroundColor)

.FHero__image
  position absolute
  width 100%
  height 100%
  background-position center
  background-size cover
  transition opacity 0.75s, background-image 0.25s

.FHero--round-bottom
  // Add 0.3% to avoid overlapping border clumsy effects
  border-bottom-right-radius 50% 4.3%
  border-bottom-left-radius 50% 4.3%

.FHero--round-bottom .FHero__image
  border-bottom-right-radius 50% 4%
  border-bottom-left-radius 50% 4%

.FHero__content
  position relative
  z-index 1
  padding rem(240) 0 rem(64)
  max-width rem(752)
  margin 0 rem(64)
  color var(--FHero--textColor)

  em
    color var(--FHero--emColor)
    font-style normal

  .FHero__title
    use-font('heading-2')

  .FHero__tag
    use-font('subtitle-1')
    margin-bottom rem(64)
    text-transform uppercase

+media-down('sm')
  .FHero__content
    margin 0 rem(24)
    padding-top rem(176)
</style>

<script setup lang="ts">
import { genCssUrl } from '@/lib/utils';

export interface ImageUrlSet {
  jpg: string;
  webp?: string;
  avif?: string;
}

export interface FHeroProps {
  /**
   * Main heading text or HTML.
   * Insert `em` in conjunction with titleEmColor to
   * emphasize some part of the text
   */
  title: string;
  /**
   * Color for the text to emphasize in heading
   */
  titleEmColor?: Color;
  /**
   * Global font color
   */
  textColor?: Color;
  /**
   * Tag text
   */
  tag?: string;
  /**
   * Round out the bottom of the image
   */
  roundBottom?: boolean;
  /**
   * Urls for the hero images - large size, mandatory
   */
  imageSet: ImageUrlSet;
  /**
   * Urls for the hero images - medium size, suitable for tablets devices, optional
   */
  imageSetMedium?: Partial<ImageUrlSet>;
  /**
   * Urls for the hero images - small size, suitable for mobile devices, optional
   */
  imageSetSmall?: Partial<ImageUrlSet>;
  /**
   * Color of the background
   */
  backgroundColor?: Color;
}

// Props declarations
const props = withDefaults(defineProps<FHeroProps>(), {
  title: '',
  textColor: 'neutral--light-5',
  titleEmColor: '',
  tag: '',
  imageSetMedium: () => ({}),
  imageSetSmall: () => ({}),
  roundBottom: false,
  backgroundColor: 'secondary',
});

const classes = computed(() => ({
  'FHero--round-bottom': props.roundBottom,
  'FHero--with-webp': !!props.imageSet.webp,
  'FHero--with-avif': !!props.imageSet.avif,
  'FHero--with-avif-and-webp': !!props.imageSet.avif && !!props.imageSet.webp,
}));

const imageJpg = computed(() => props.imageSet.jpg);
const imageWebp = computed(() => props.imageSet.webp);
const imageAvif = computed(() => props.imageSet.avif);

const imageMediumJpg = computed(
  () => props.imageSetMedium.jpg || imageJpg.value
);
const imageMediumWebp = computed(
  () => props.imageSetMedium.webp || imageWebp.value
);
const imageMediumAvif = computed(
  () => props.imageSetMedium.avif || imageAvif.value
);

const imageSmallJpg = computed(
  () => props.imageSetSmall.jpg || imageMediumJpg.value
);
const imageSmallWebp = computed(
  () => props.imageSetSmall.webp || imageMediumWebp.value
);
const imageSmallAvif = computed(
  () => props.imageSetSmall.avif || imageMediumAvif.value
);

const { webpSupport, avifSupport, checked } = useImageSupport({});

const backgroundImageLarge = computed(() => {
  const withAvif = !!props.imageSet.avif;
  const withWebp = !!props.imageSet.webp;
  // Avif being the lightest, if supported we use it instead of webp
  if (withAvif && avifSupport.value) return genCssUrl(imageAvif.value);
  if (withWebp && webpSupport.value) return genCssUrl(imageWebp.value);
  return genCssUrl(imageJpg.value);
});

const backgroundImageMedium = computed(() => {
  const withAvif = !!props.imageSetMedium.avif;
  const withWebp = !!props.imageSetMedium.webp;
  // Avif being the lightest, we use it first instead of webp
  if (withAvif && avifSupport.value) return genCssUrl(imageMediumAvif.value);
  if (withWebp && webpSupport.value) return genCssUrl(imageMediumWebp.value);
  return genCssUrl(imageMediumJpg.value);
});

const backgroundImageSmall = computed(() => {
  const withAvif = !!props.imageSetSmall.avif;
  const withWebp = !!props.imageSetSmall.webp;
  // Avif being the lightest, if supported we use it instead of webp
  if (withAvif && avifSupport.value) return genCssUrl(imageSmallAvif.value);
  if (withWebp && webpSupport.value) return genCssUrl(imageSmallWebp.value);
  return genCssUrl(imageSmallJpg.value);
});

// As the use of CSS var for background-image urls results in blinks of the hero on resize,
// we must use JS-side responsivity and plug the image url value directly into `background-image` style
const { isMdAndDown, isSmAndDown } = useFBreakpoints();
const backgroundImage = computed(() => {
  if (isSmAndDown.value) return backgroundImageSmall.value;
  if (isMdAndDown.value) return backgroundImageMedium.value;
  return backgroundImageLarge.value;
});

const imageStyle = computed(
  (): Style => ({
    'background-image': backgroundImage.value,
    opacity: checked.value ? 1 : 0,
  })
);

const style = computed(
  (): Style => ({
    '--FHero--textColor': getCssColor(props.textColor),
    '--FHero--emColor': getCssColor(props.titleEmColor),
    '--FHero--backgroundColor': getCssColor(props.backgroundColor),
  })
);
</script>
