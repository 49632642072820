<template lang="pug">
FGrid(
  gap="32"
  columns="6"
)
  FGridItem(
    v-for="(serviceCard, index) in serviceCards"
    :key="index"
    v-bind="computeCardSpan(index)"
  )
    FCard.FServicesGrid__card(
      :color="serviceCard.color"
      :text-color="serviceCard.textColor"
    )
      h4.FServicesGrid__cardHeading(v-html="serviceCard.heading")
      .FServicesGrid__cardImageContainer(
        :style="computeCardImageContainerStyle(serviceCard.image)"
      )
        FBackgroundImage(
          :src="serviceCard.image.src"
          :size="serviceCard.image.fit ?? 'fit'"
          :position="serviceCard.image.position ?? ''"
          :placeholder="serviceCard.image.placeholder"
        )
</template>

<style lang="stylus">
.FServicesGrid__card
  overflow hidden
  height rem(504)
  display flex
  position relative
  flex-direction column

  &:last-child .FBackgroundImage__image
    border-top-left-radius rem(16)

.FServicesGrid__cardHeading
  text-align center
  padding rem(40) rem(24) 0
  max-width 100%
  margin auto

.FServicesGrid__cardImageContainer
  width 100%
  height 100%

.FServicesGrid__cardImage
  width 100%
  height 100%
  background-repeat no-repeat
</style>

<script setup lang="ts">
import type { FCardProps, FGridItemProps } from '@fifteen/design-system-vue';

export interface FServiceCardImage {
  /**
   * Url of the image
   */
  src: string;
  /**
   * Alternative text of the image
   */
  alt: string;
  /**
   * Background image size fit mode
   */
  fit?: 'contain' | 'cover';
  /**
   * Background image position
   */
  position?: string | number;
  /**
   * Container height
   */
  height?: string | number;
  /**
   * Container width
   */
  width?: string | number;
  /**
   * Container top position
   */
  top?: string | number;
  /**
   * Container left position
   */
  left?: string | number;
  /**
   * Container right position
   */
  right?: string | number;
  /**
   * Container bottom position
   */
  bottom?: string | number;
  /**
   * Placeholder of the image
   */
  placeholder?: string;
}

export interface FServiceCard extends Omit<FCardProps, 'width'> {
  /**
   * Heading of the card
   */
  heading: string;
  /**
   * Image settings (used as a background image of its container)
   */
  image: FServiceCardImage;
}

export interface FServicesGridProps {
  /**
   * Array of service cards to display
   */
  serviceCards: FServiceCard[];
}

const props = withDefaults(defineProps<FServicesGridProps>(), {
  serviceCards: () => [],
});

/**
 * Compute card image container style. We use padding to position its inner image.
 * @param image - Image settings
 * @returns The computer style
 */
function computeCardImageContainerStyle(image: FServiceCardImage): Style {
  const { top, left, right, bottom } = image;
  return {
    position: 'relative',
    marginTop: genSize(top),
    marginLeft: genSize(left),
    marginRight: genSize(right),
    marginBottom: genSize(bottom),
  };
}

/**
 * Calculate the correct grid span of a service card depending on the window's current breakpoint and its position on the grid
 * @param index - Index of the card
 * @returns The calculated grid span
 */
function computeCardSpan(index: number): FGridItemProps {
  const lastServiceIndex = props.serviceCards.length - 1;
  return {
    xxs: 6,
    xs: 6,
    sm: index === lastServiceIndex ? 6 : 3,
    md: index === lastServiceIndex ? 4 : 2,
    lg: index > lastServiceIndex - 2 ? 3 : 2,
    span: index > lastServiceIndex - 2 ? 3 : 2,
  };
}
</script>
