<template lang="pug">
.FAvatarHive(:style="style")
  .FAvatarHive__container
    .FAvatarHive__avatarContainer(
      v-for="i in 6"
      :key="'avatar-' + i"
    )
      FAvatar(
        size="100%"
        v-bind="avatars[(i - 1) % avatars.length]"
      )
    FLogo.FAvatarHive__logo(variant="image-only")
</template>

<style lang="stylus">
.FAvatarHive
  display flex
  justify-content center
  width 100%

.FAvatarHive__container
  display flex
  justify-content center
  position relative
  height 0
  width 100%
  max-width var(--FAvatarHive--width)
  padding-top var(--FAvatarHive--height)

.FAvatarHive__avatarContainer
  width 28%
  position absolute

  &:nth-child(1)
    top 0

  &:nth-child(2),
  &:nth-child(4)
    left 0

  &:nth-child(3),
  &:nth-child(5)
    right 0

  &:nth-child(2),
  &:nth-child(3)
    top 21%
    transform translateY(-21%)

  &:nth-child(4),
  &:nth-child(5)
    top 73%
    transform translateY(-73%)

  &:nth-child(6)
    bottom 0

.FAvatarHive__logo
  position absolute
  top 50%
  transform translateY(-50%)
  width 33%
</style>

<script setup lang="ts">
import type { FAvatarProps } from '@fifteen/design-system-vue';

export interface FAvatarHiveProps {
  /**
   * Avatars props, you can provide a max of 6
   */
  avatars: Omit<FAvatarProps, 'size'>[];
  /**
   * Width of the component
   */
  width?: string | number;
}

const props = withDefaults(defineProps<FAvatarHiveProps>(), {
  width: 640,
});

const style = computed(
  (): Style => ({
    '--FAvatarHive--width': genSize(props.width),
    '--FAvatarHive--height': `min(100%, ${genSize(props.width)})`,
  })
);
</script>
